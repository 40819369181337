const glossaryObj = {
  "MARKET CAP (MM)":
    "Last close Market capitalization is the aggregate valuation of the company based on its last close share price and the last close number of outstanding stocks. It is calculated by multiplying the last close market price of the company's share with the last close outstanding shares of the company.  Last Close Market Capitalization is calculated using the following formula: Last Close BS Shares out * Last Close Price",
  "TEV (MM)":
    "Last Total Enterprise Value (TEV) represents the cash-less value of a firm as an asset.  In theory, this should be equal to the present value of expected cash flows discounted at the Weighted Average Cost of Capital (WACC). These cash flows are un-levered and available to all suppliers of capital. Total Enterprise Value is calculated as: Market Capitalization + Book Value of Total Debt + Book Value of Preferred Stock + Book Value of Minority Interest - Cash & Short Term Investments - Long Term Marketable Securities",
  "Target Stock Price Mean":
    "It is the stock price analysts expect to see within a period of 0-18 months",
  "Target Stock Price Median":
    "The short-term target price as provided by contributing Wall Street analysts as aggregated by S&P Global CapitalIQ. Short-term target prices cover a horizon between 0 and 18 months, with a horizon of 6 to 12 months being the most widely used. Price target is a non-periodic estimate. The estimate is not for a specified time period and as such will only have one estimate, unlike the usual multiple estimates and will also have no comparable actual figure. The Median is the middle estimate in an ordered list of broker estimates. If the number of estimates is even, then the average of the middle two figures will be the median",
  "Target Stock Price High":
    "The short-term target price as provided by contributing Wall Street analysts as aggregated by S&P Global CapitalIQ. Short-term target prices cover a horizon between 0 and 18 months, with a horizon of 6 to 12 months being the most widely used. Price target is a non-periodic estimate. The estimate is not for a specified time period and as such will only have one estimate, unlike the usual multiple estimates and will also have no comparable actual figure. High is the highest estimate currently forecast for the specified data item and period end. Estimates that are currently suppressed will not be included in the calculation of the high estimate",
  "Target Stock Price Low":
    "The short-term target price as provided by contributing Wall Street analysts as aggregated by S&P Global CapitalIQ. Short-term target prices cover a horizon between 0 and 18 months, with a horizon of 6 to 12 months being the most widely used. Price target is a non-periodic estimate. The estimate is not for a specified time period and as such will only have one estimate, unlike the usual multiple estimates and will also have no comparable actual figure. Low is the lowest estimate currently forecast for the specified data item and period end",
  "Target Stock Price (# Est)":
    "Number of Estimates is the total number of analysts forecasting for this specified data item and period end",
  "Net Asset Value / Share Mean":
    "The tangible book value of a company's assets minus its current and long term liabilities adjusted as appropriate and divided by the shares in issue at year-end. NAVPS is often used as an indication that a stock may be undervalued or overvalued in relation to the physical asset worth. Mean is the arithmetical mean average of the forecasts",
  "NTM Total Enterprise Value / Revenues":
    "Total Enterprise Value divided by the Next Twelve Months estimated Revenues. Most analysts define Revenue as the total proceeds from the sale of products and/or services",
  "NTM Total Enterprise Value / EBITDA":
    "Total Enterprise Value divided by the Next Twelve Months estimated EBITDA. Most analysts define EBITDA as an unlevered normalized measure of operating earnings before the impact of accounting and financing decisions. It excludes exceptional gains or charges from unusual and discontinued activities, as well as depreciation of fixed assets and amortization of intangibles. This is a Non-GAAP measure",
  "NTM Total Enterprise Value / EBIT":
    "Total Enterprise Value divided by the Next Twelve Months estimated EBIT. Most analysts define Earnings Before Interest & Taxes (EBIT) as Operating Income normalized for unusual items such as merger/restructuring charges, gain/losses related to legal settlements and non-recurring gain/losses on the sale or write-down of assets",
  "NTM Price / Normalized Earnings":
    "Stock Price divided by the Next Twelve Months estimated EPS Normalized. Denominator is defined as Earnings Per Share Excluding Exceptional Items. Most analysts define EPS Normalized as the adjusted EPS for pre-and after-tax unusual gains or losses (Exceptional Items) divided by the number of Diluted Weighted Average Shares Outstanding (WASO). This is usually similar to Non-GAAP or Adjusted EPS in the denominator",
  "NTM Market Cap / Free Cash Flow":
    "Market Capitalization divided by the Next Twelve Months estimated Levered Free Cash Flow. Levered FCF measures the discretionary net cash flows a company can distribute to its shareholders after it meets its interest payment and tax obligations",
  "LTM Total Enterprise Value / Revenues":
    "Total Enterprise Value divided by the Last Twelve Months actual Revenues",
  "LTM Total Enterprise Value / EBITDA":
    "Total Enterprise Value divided by the Last Twelve Months actual EBITDA",
  "LTM Total Enterprise Value / EBIT":
    "Total Enterprise Value divided by the Last Twelve Months actual EBIT",
  "LTM Price / Diluted EPS (P/E)":
    "Stock price divided by the Last Twelve Months actual Diluted Earnings per Share. The denominator is defined as the Diluted Net income / Diluted Weighted Average Shares Outstanding",
  "LTM Price / Book Value per Share":
    "Stock price divided by the Last Twelve Months actual Book Value per Share. The denominator BV / Share is defined as Total Common Equity / Shares Outstanding",
  "LTM Price / Tangible Book Value per Share":
    "Stock price divided by the Last Twelve Months actual Tangible Book Value per Share. Tangible Book Value represents book value of common equity, net of goodwill and intangible assets. Tangible Book Value = Total Common Equity - Goodwill - Other Intangibles",
  "LTM Total Enterprise Value / Unlevered Free Cash Flow":
    "Total Enterprise Value divided by the Last Twelve Months actual Unlevered Free Cash Flow. Unlevered FCF = EBIT * (1 - Tax Statutory Rate)  + Depreciation & Amortization + Amortization of Deferred Charges - Capital Expenditure + Sale (Purchase) of Intangible Assets + Total Stock-Based Compensation - Change In Net Working Capital. S&P Global Capital IQ assumes statutory tax rate of 37.5% for all companies",
  "LTM Market Cap / Levered Free Cash Flow":
    "Total Market Capitalization divided by the Last Twelve Months actual Levered Free Cash Flow. Levered FCF = EBIT * (1 - Tax Statutory Rate)  + Interest Expense * (1 - Tax Statutory Rate)  + Depreciation & Amortization + Amortization of Deferred Charges - Capital Expenditure + Sale (Purchase) of Intangible Assets + Total Stock-Based Compensation - Change In Net Working Capital. S&P Global Capital IQ assumes statutory tax rate of 37.5% for all companies",
  "NTM Revenues":
    "Next Twelve Months estimated Revenues. Most analysts define Revenue as the total proceeds from the sale of products and/or services",
  "NTM EBITDA":
    "Next Twelve Months estimated EBITDA. Most analysts define EBITDA as an unlevered normalized measure of operating earnings before the impact of accounting and financing decisions. It excludes exceptional gains or charges from unusual and discontinued activities, as well as depreciation of fixed assets and amortization of intangibles. This is a Non-GAAP measure",
  "NTM EBIT":
    "Next Twelve Months estimated EBIT. Most analysts define Earnings Before Interest & Taxes (EBIT) as Operating Income normalized for unusual items such as merger/restructuring charges, gain/losses related to legal settlements and non-recurring gain/losses on the sale or write-down of assets",
  "NTM Normalized Earnings Per Share":
    "Next Twelve Months estimated EPS Normalized. Most analysts define EPS Normalized as the adjusted EPS for pre-and after-tax unusual gains or losses (Exceptional Items) divided by the number of Diluted Weighted Average Shares Outstanding (WASO). This is usually similar to Non-GAAP or Adjusted EPS",
  "NTM Free Cash Flow":
    "Next Twelve Months estimated Levered Free Cash Flow. Levered FCF measures the discretionary net cash flows a company can distribute to its shareholders after it meets its interest payment and tax obligations",
  "NTM Cash Flow Per Share":
    "(Cash from Operations + Preferred Dividends Paid - Capital Expenditure + Sale (Purchase) of Intangible Assets) / Basic Weighted Average Shares Outstanding",
  "LTM Diluted EPS Before Extra":
    "Diluted Net income / Diluted Weighted Average Shares Outstanding",
  "LTM Book Value per Share":
    "BV / Share is defined as Total Common Equity / Shares Outstanding",
  "LTM Tangible Book Value per Share":
    "Tangible Book Value represents book value of common equity, net of goodwill and intangible assets. Tangible Book Value = Total Common Equity - Goodwill - Other Intangibles",
  "LTM Unlevered Free Cash Flow":
    "Unlevered FCF = EBIT * (1 - Tax Statutory Rate)  + Depreciation & Amortization + Amortization of Deferred Charges - Capital Expenditure + Sale (Purchase) of Intangible Assets + Total Stock-Based Compensation - Change In Net Working Capital. S&P Global Capital IQ assumes statutory tax rate of 37.5% for all companies",
  "LTM Levered Free Cash Flow":
    "Levered FCF = EBIT * (1 - Tax Statutory Rate)  + Interest Expense * (1 - Tax Statutory Rate)  + Depreciation & Amortization + Amortization of Deferred Charges - Capital Expenditure + Sale (Purchase) of Intangible Assets + Total Stock-Based Compensation - Change In Net Working Capital. S&P Global Capital IQ assumes statutory tax rate of 37.5% for all companies",
  "Return on Assets %":
    "(EBIT * 0.625) / ((Total Assets (t) + Total Assets (t-1)) / 2)  Notes: If both periods of data (t and t-1) are not available then the ratio will be shown as NM. If the denominator is less than or equal to zero then the ratio will be shown as NM",
  "Return on Capital %":
    "EBIT / (Total Equity + Total Debt + Deferred Tax Liability Non Current + Deferred Tax Liability Current. Notes: If the denominator is less than or equal to zero then the ratio will be shown as NM. If the return is less than (300%) then the value will be shown as NM",
  "Return On Equity %":
    "Earnings From Continuing Operations / ((Total Equity (t) + Total Equity (t-1)) / 2)",
  "Return on Common Equity %":
    "Net Income to Common Excl. Extra Items / ((Total Common Equity (t) + Total Common Equity (t-1)) / 2)",
  "Gross Profit Margin %": "Gross Profit / Total Revenues",
  "SG&A Margin %": "Selling General & Admin Expenses / Total Revenues",
  "EBITDA Margin %": "(EBIT + D&A)/ Total Revenues",
  "EBITA Margin %":
    "(EBIT + Amortization of Goodwill and Intangible Assets) / Total Revenues",
  "EBIT Margin %": "EBIT / Total Revenues",
  "EBT Excl. Non-Recurring Items Margin %":
    "EBT, Excl. Unusual Items / Total Revenues. EBT, Excl. Unusual Items is defined as Operating Income - Net Interest Expenses + Other Non Operating Expenses",
  "Income From Continuing Operations Margin %":
    "Earnings From Continuing Operations / Total Revenues",
  "Net Income Margin %": "Net Income / Total Revenues",
  "Normalized Net Income Margin %":
    "Normalized Net Income / Total Revenues. Normalized Net Income is defined as EBT, Excl. Unusual Items * (1 - Statutory Tax Rate)  + Minority Int. in Earnings",
  "Net Avail. For Common Margin %":
    "(Net Income to Common Incl Extra - Earnings Of Discontinues Operations - Extraordinary Item & Accounting Change) / Total Revenues",
  "Levered Free Cash Flow Margin %": "Levered Free Cash Flow / Total Revenues",
  "Unlevered Free Cash Flow Margin %":
    "Unlevered Free Cash Flow / Total Revenues",
  "Asset Turnover":
    "Total Revenues / ((Total Assets (t) + Total Assets (t-1) / 2)",
  "Fixed Assets Turnover":
    "Total Revenues / ((Net Property Plant and Equipment (t) + Net Property Plant and Equipment (t-1)) / 2)",
  "Receivables Turnover":
    "Revenues / ((Accounts Receivable (t) + Accounts Receivable (t-1)) / 2)",
  "Inventory Turnover":
    "Cost of Goods Sold / ((Inventory (t) + Inventory (t-1)) / 2)",
  "Current Ratio": "Total Current Assets / Total Current Liabilities",
  "Quick Ratio":
    "(Total Cash And Short Term Investments + Accounts Receivable + Other Receivables) / Total Current Liabilities",
  "Dividend Yield %":
    "Dividend Per Share divided by Share Price as on Closing Date",
  "Op Cash Flow to Current Liab":
    "Cash from Operations / Total Current Liabilities",
  "Avg. Cash Conversion Cycle":
    "((Inventory (t) + Inventory (t-1)) / 2) / Cost Of Goods Sold * (Number of Days in the Period) + ((Accounts Receivable (t) + Accounts Receivable (t-1)) / 2) / Revenues * (Number of Days in the Period) - ((Accounts Payable (t) + Accounts Payable(t-1)) / 2) / (Cost of Goods Sold (t) - Inventory (t-1) + Inventory (t)) * (Number of Days in the Period)",
  "Avg. Days Sales Outstanding":
    "((Accounts Receivable (t) + Accounts Receivable, Total (t-1)) / 2) / Revenues * (Number of Days in the Period)",
  "Avg. Days Outstanding Inventory":
    "((Inventory (t) + Inventory (t-1)) / 2) / Cost of Goods Sold",
  "Avg. Days Payable Outstanding":
    "((Accounts Payable (t) + Accounts Payable (t-1)) / 2) / (Cost of Goods Sold (t) - Inventory (t-1) + Inventory (t)) * (Number of Days in the Period)",
  "Total Debt / Capital":
    "Total Debt / (Total Equity + Total Debt + Deferred Tax Liability Non Current + Deferred Tax Liability Current)",
  "Total Liabilities / Total Assets":
    "(Total Current Liabilities + Total Long-Term Liabilities) / Total Assets",
  "FFO Interest Coverage":
    "(Cash from Operations - Net Cash From Discontinued Operations) / Interest Expense",
  "FFO to Total Debt (x)":
    "(Cash from Operations - Net Cash From Discontinued Operations) / Total Debt",
  "Total Average Common Equity / Total Average Assets %":
    "(Total Common Equity (t) + Total Common Equity (t-1)) / 2) / ((Total Assets (t) + Total Assets (t-1)) / 2)",
  "Total Equity + Allowance for Loan Losses / Total Loans %":
    "(Total Preferred Equity + Total Common Equity + Allowance for Loan Losses) / Gross Loans",
  "Tier 1 Capital Ratio %":
    "Represents Tier 1 Capital as a percentage of Total Risk-Weighted Assets of the Bank",
  "Total Capital Ratio %":
    "Represents Total Capital as a percentage of Total Risk-Weighted Assets of the Bank",
  "Core Tier 1 Capital Ratio %":
    "Expressed as a percentage of core tier 1 capital to that of risk-weighted assets",
  "Tier 2 Capital Ratio %":
    "Expressed as a percentage of tier 2 capital to that of risk-weighted assets",
  "Equity Tier 1 Capital Ratio %":
    "Expressed as a percentage of equity tier 1 capital to that of risk-weighted assets",
  Revenues: "Proceeds from the sale of products and/or services",
  "Other Revenues (Summary Subtotal)":
    "Sum of the following data items:  Finance Div. Revenues, Insurance Division Revenues, Gain (Loss) on Sale of Assets, Gain (Loss) on Sale of Investment, Interest and Invest. Income, and Other Revenues",
  "Insurance Division Revenues":
    "Insurance Division Premiums + Insurance Division Other Revenues",
  "Interest And Invest. Income (Rev)":
    "Interest Income On Mortgage Securities + Interest and Dividend Income + Interest and Investment Income",
  "Other Revenues":
    "Other Revenues represents income earned from pursuing some other line of activity along with the primary activities of the regular course of business",
  "% Change YoY":
    "Percent change year-over-year. Defined as (this year)/(last year) - 1",
  "Cost of Goods Sold":
    "Cost of Goods Sold represents cost of revenue incurred on all raw materials, work in process, manufacturing expenses and other costs directly attributable to production of finished goods and operating revenues. This refers to the total cost of manufacturing and delivering the product or service to consumers",
  "Gross Profit": "Total Revenues - Cost of Revenues",
  "% Gross Margins": "Gross Profit / Total Revenues",
  "Selling General & Admin Expenses":
    "Represents all costs incurred towards selling, general and administrative expense. It is a composition of advertising, marketing, administrative, plan contributions, delivery and distributions; storage, employee benefits; general and other indirect production related expenditures",
  "Provision for Bad Debts":
    "Represents the provision recorded by the company based on the doubtful receipt of some trade receivables (receivables, which are formed through credit sales)",
  "Pre-Opening Costs":
    "Represents the expenses incurred by a company before starting the business or product. Generally these expenses are disclosed as Pre-Opening or Start-up costs",
  "R&D Expense":
    "Represents the following costs incurred: Development of a new product, innovation relating to technology formulation, process development, the process undertaken in upgrading the existing product or service line. This items also includes Stock-Based Compensation for R&D",
  "R&D Expenses":
    "Represents the following costs incurred: Development of a new product, innovation relating to technology formulation, process development, the process undertaken in upgrading the existing product or service line. This items also includes Stock-Based Compensation for R&D",
  "Operating Income": "Total Revenues - Total Operating Expenses",
  "% Operating Margins": "Operating Income / Total Revenues",
  "Interest Expense":
    "Represents the interest expense incurred by the company on debt taken",
  "Income (Loss) On Equity Invest.":
    "This item includes Parent/Investor Company's share of earnings/profit derived from equity method of investments in affiliates/joint ventures/partnership firms and unconsolidated subsidiaries",
  "Currency Exchange Gains (Loss)":
    "Represents the impact of foreign currency exchange rate fluctuations on a company's financials",
  "Restructuring Charges":
    "Represents costs related to restructuring activities, realignment of business strategy, streamlining of operations and cost cutting initiatives",
  "Asset Writedown":
    "Represents the write down or impairment in the value of tangible and intangible assets, when the market value or present value of future cash inflows is lower than the book value at which the asset is carried in the balance sheet",
  "Legal Settlements":
    "Represents costs incurred in legal disputes or receipts from legal settlements received by the company and reflected on the income statement",
  "Income Tax Expense":
    "Represents Income Tax on the profits earned during a particular period or financial year to the government",
  "Net Income to Common Incl Extra Items": "Net Income - Preferred Dividend",
  "Net Income to Common Excl. Extra Items":
    "Net Income - Preferred Dividend - Earnings Of Discontinued Operations - Extraordinary Items",
  "Diluted EPS Excl Extra Items":
    "Net income / Diluted Weighted Average Shares Outstanding",
  "Dividends per Share":
    "Represents dividend per common share declared in the form of cash",
  "Special Dividends Per Share":
    "Represents special dividend per share for any class of common stock declared in the form of cash",
  "FFO Payout Ratio %": "Common Dividends Paid / FFO",
  EBITDA: "EBIT + D&A",
  EBITDAR: "EBITDA + Net Rental Expense",
  "Selling and Marketing Expense":
    "Advertising Expense + Marketing Expense + Stock-Based Compensation for Marketing",
  "Effective Tax Rate %": "Income Tax Expense / EBT, Inc. Unusual Items",
  "Free Cash Flow": "Cash from Operations - Capital Expenditure",
  "Capital Expenditure":
    "Represents cash outflows towards purchase of plant, property and equipment by the company",
  "Sale of Property, Plant, and Equipment":
    "Cash received from the sale of plant, property and equipment",
  "Cash Acquisitions":
    "Represents cash paid for the acquisition of a company, stake in subsidiary, business, new product line or an operation",
  Divestitures:
    "Represents the cash inflows from the selling of a subsidiary, business units, product divisions or segments of business operations",
  "Net (Increase) Decrease in Loans Originated / Sold - Investing":
    "Represents extension and repayments of loans by the company in the course of its lending business activity",
  "Long-Term Debt Issued":
    "Represents cash inflows on issuance of any debt that is long-term in nature",
  "Long-Term Debt Repaid":
    "Represents cash outflow towards repayment of long-term debt",
  "Common Dividends Paid":
    "Represents dividends paid or distributions made to common shareholders",
  "Special Dividend Paid":
    "Represents any special dividend/distribution to equity shareholders apart from the normal dividend distribution",
  "Foreign Exchange Rate Adjustments":
    "Represents adjustments resulting from currency translation in the preparation of financial statements by the company",
  "Net Change in Cash":
    "Represents net increase or decrease in cash or cash equivalents during the stated period of time. Calculated as Cash from Operations + Cash from Investing + Cash from Financing + Foreign Exchange rate Adjustments + Miscellaneous Cash Flow Adjustments",
  "Cash And Equivalents":
    "Represents funds in the form of cash, readily convertible deposits, securities and other instruments having maturities of less than 3 months at the time of purchase",
  "Accounts Receivable":
    "Represents the amount due from customers arising from transactions in the ordinary course of business, less allowances due to uncollectability and any amounts discounted",
  "Notes Receivable": "Represents short-term loans payable to the company",
  "Total Receivables":
    "Accounts Receivable + + Other Receivables + Notes Receivable",
  "Prepaid Expenses":
    "Represents amounts paid to other parties for services to be rendered in the future. They expire and become expenses with the passage of time, usage or events, e.g., prepaid rent, prepaid insurance, etc.",
  "Loans Held For Sale":
    "Represents loans recoverable by the company and held for sale in securitization transactions",
  "Deferred Tax Assets Current":
    "Represents reversible differences between financial and tax reporting basis of assets that result into net tax deductions or carry forwards in future years",
  "Restricted Cash":
    "Represents cash and cash equivalents, which are restricted for use or transfer, and normally consists of funds held in escrow or cash restricted in use",
  "Gross Property Plant And Equipment":
    "Represents the gross value (not net of depreciation), stated at cost, of tangible assets that are durable in nature and are used in the normal course of business as opposed to being held for sale",
  "Accumulated Depreciation":
    "Represents the period-end balance of accumulated depreciation on property, plant and equipment",
  Goodwill:
    "Represents premium purchase price paid over the book value of net assets acquired",
  "Other Intangibles":
    "Represents the net values (i.e. gross intangibles as reduced by the accumulated amortization) of those assets that provide future economic benefit to the Company but have no physical substance",
  "Accounts Payable":
    "Represents the amount which a company owes to vendors for products and services purchased on credit (due in customary trade terms within one year or normal operating cycle of the company)",
  "Accrued Expenses":
    "Represents the expenses that have been incurred, but have not yet paid for, during a given accounting period",
  "Current Portion of Long-Term Debt":
    "Represents the portion of long-term debt due within one year",
  "Deferred Tax Liability Current":
    "Represents taxes payable due within a year on income earned for accounting but not tax purposes",
  "Unearned Revenue Non Current":
    "Represents revenues received in the current period but expected to be recognized as revenues in subsequent accounting periods",
  "Common Stock": "Represents the shareholder's equity stake in a company",
  "Additional Paid In Capital":
    "Represents capital contributions by common shareholders that are in excess of the par or stated value of common stock",
  "Retained Earnings":
    "Represents earnings not distributed to shareholders nor apportioned for any specific purpose and can therefore be reinvested in the business",
  "Treasury Stock":
    "Represents the company's Common Stock that had been issued, subsequently reacquired by the company and not yet reissued or cancelled",
  "Tangible Book Value":
    "Represents book value of common equity, net of goodwill and intangible assets",
  "Net Debt": "Total Debt - Total Cash & Short-term Investments",
  "Revenue Actual":
    "The total amount of money billed for a company's products or services. . Actuals reﬂect a company’s ﬁnancial results, standardized to reﬂect the estimate analysts’ calculation methodology. Estimates are an aggregate of Wall Street analyst forecasts",
  "EBITDA Actual":
    "Revenues minus expenses excluding the effects of interest income or expense, taxation, depreciation and amortization. EBITDA includes both operating and non-operating income. EBITDA is often used to analyze the potential cash flow of a company or to compare companies' operating performance without the distorting effects of amortization and depreciation",
  "EBIT Actual":
    "Revenues minus expenses excluding the effects of interest expense or expense and taxation. EBIT includes both operating and non-operating income. EBIT is often used as a measure of a company's operating performance",
  "EBT Normalized Actual":
    "Revenue minus all expenses including operating and non-operating, interest expense and income adjusted to exclude the effects of exceptional items",
  "Net Income Normalized Actual":
    "Revenue minus all expenses including operating and non-operating, interest income and expense, and taxation adjusted to exclude the effects of exceptional items",
  "EPS Normalized Actual":
    "The profit of a company from continuing operations and usually excludes unusual items, accounting changes, stock based compensation, and discontinued operations divided by the number of common shares outstanding (diluted). This is generally most similar to Non-GAAP or Adjusted EPS",
  "EPS (GAAP) Actual":
    "The portion of a company's profit allocated to each outstanding share of common stock. EPS serves as an indicator of a company's profitability. It includes all one-time, exceptional or extraordinary incomes/expenditures per GAAP accounting",
  "Dividend Per Share":
    "Represents gross dividend per common share declared in the form of cash",
  "Gross Margin Actual (%)":
    "Company's total revenue minus cost of goods sold, divided by the total revenue, expressed as a percentage",
  "Interest Expense Actual":
    "Interest Expense as the amount a company pays in interest on the debt borrowed",
  "Depreciation & Amortization Actual":
    "Non-cash periodic charge on the account of wear and tear of fixed assets used in the business. This item also includes amortization of all intangible assets and goodwill",
  "Effective Tax Rate Actual (%)":
    "Effective Tax Rate as the net rate a company pays on income that includes all forms of taxes. It is calculated by dividing the total tax paid by taxable income",
  "EBT (Excl. Excep & GW) Actual":
    "Revenue minus all expenses including operating and non-operating, interest expense and income adjusted to exclude the effects of exceptional items and Amortization of Goodwill",
  "EBT (GAAP) Actual":
    "Revenue minus all expenses including operating and non-operating, interest expense and income",
  "Net Income (Excl. Excep & GW) Actual":
    "Revenue minus all expenses including operating and non-operating expenses, interest income and expense, and taxation adjusted to exclude the effects of exceptional items and amortization of goodwill",
  "Net Income (GAAP) Actual":
    "Revenue minus all expenses including operating and non-operating, interest expense and income and taxation",
  "Cash EPS Actual":
    "The portion of a company's profit plus non-cash items which allocated to each outstanding share of common stock. Cash EPS is a company’s net income, plus depreciation, plus amortization of goodwill, intangibles, and prepaid assets (non-cash items), divided by diluted weighted average shares outstanding",
  "Cash From Operations Actual":
    "Net cash used or generated in operating activities during the stated period of time. It reflects the net impact of all operating activity transactions on the cash flow of the entity",
  "Capital Expenditure Actual":
    "The outlay of money to acquire or improve capital assets such as buildings or machinery",
  "Free Cash Flow Actual":
    "Free cash flow represents the cash that a company is able to generate after laying out the money required to maintain or expand its asset base",
  "Cash Flow / Share Actual":
    "Net earnings excluding the non-cash items depreciation and amortization but including the cash items net interest and taxation divided by the weighted average number of diluted shares outstanding",
  "Net Debt Actual":
    "Short term and long term interest-bearing debt minus cash and cash equivalents",
  "Book Value / Share Actual":
    "A measure used by owners of common shares in a firm to determine the level of safety associated with each individual share after all debts have been paid",
  "Net Asset Value Actual":
    "The tangible book value of a company's assets minus its current and long term liabilities adjusted as appropriate",
  "Net Asset Value / Share Actual":
    "The tangible book value of a company's assets minus its current and long term liabilities adjusted as appropriate and divided by the shares in issue at year-end. NAVPS is often used as an indication that a stock may be undervalued or overvalued in relation to the physical asset worth",
  "Return on Equity Actual (%)":
    "This ratio represents earnings from continuing operations divided by average total equity and is expressed as a percent",
  "Return on Assets Actual (%)":
    "A measure to judge the profitability of the company in relation to its total assets. It is calculated by dividing a company's annual earnings by its total assets. ROA indicates what earnings were generated from invested capital (assets)",
  "Distributable Cash (Income Trusts) Actual":
    "Distributable cash is a term unique to income funds and trusts. Distributable Cash as the net cash generated by the operating entity’s business that is ultimately available for distribution to the income trust’s unit holders",
  "Distributable Cash / Share (Income Trust) Actual":
    "Distributable cash is a term unique to income funds and trusts. Distributable Cash as the net cash generated by the operating entity’s business that is ultimately available for distribution to the income trust’s unit holders. Total amount of Distributable Cash/Cash Distributions divided by the total number of units or shares issued and held by holders entitled to cash distribution",
  "FFO (REIT) Actual":
    "Net income excluding gains or losses from sales of property or debt restructuring, adding back depreciation and amortization of real estate and after adjustments for unconsolidated partnerships and joint ventures. FFO includes both recurring and non-recurring results of operations. This figure represents a supplemental measure of a company's operating performance, is used to define the cash flow from trust operations, and is provided for North American Real Estate Investment Trust Companies only",
  "FFO / Share (REIT) Actual":
    "Net income excluding gains or losses from sales of property or debt restructuring, adding back depreciation and amortization of real estate and after adjustments for unconsolidated partnerships and joint ventures. This amount is then divided by the average number of shares in issue. FFO includes both recurring and non-recurring results of operations. This figure represents a supplemental measure of a company's operating performance, is used to define the cash flow from trust operations, and is provided for North American Real Estate Investment Trust Companies only",
  "Adjusted FFO / Share (REIT) Actual":
    "Adjusted FFO/Share metric uses as a financial measure of REITs operating performance. The AFFO of a REIT, though subject to varying methods of computation, is generally equal to funds from operations (FFO) adjusted for maintenance capital expenditures. Analyst calculations also take into account GAAP straight-lining of rent, leasing costs and other material factors which divided by the average number of shares in issue",
  "Same Store Sales Growth Actual (%)":
    "Same Store Sales Growth as a percentage of revenue growth generated by retail chains over a certain amount of time, which is then compared to an identical period in the past, usually in the previous year. This only includes stores that have been operating for over a year or more",
  "Total Production (Oil) Actual":
    "Total Production (Oil) Estimate as the total quantity of oil produced during a particular period. Oil is also referred to as Crude Oil, Petroleum, Coal Oil, Rock oil, Fossil Oil, etc.",
  "Total Production Actual":
    "Total Production Estimate as production is a measure of the amount of gas or other hydrocarbons that are produced from wells that a gas company has interests in. Since the outputs of crude oil and natural gas liquids are measured in terms of barrels, and output of natural gas is measured in cubic feet/cubic meters, there must be a way to convert each hydrocarbon to a standardized form of measurement so that the total production of a company can be analyzed",
  "LTM Price / Net Current Asset Value":
    "[(Current Assets - Total Liabilities - Minority Interest - Preferred Stock) / Shares Outstanding]",
}

export default glossaryObj
